import Vue from '@/vueTyped';
import { VueConstructor } from 'vue';
import { MBAddressObj } from '@/types';

export default Vue.extend({
    inject: {
        promptRoot: {
            default: {
                timestampOverride: '',
                locationOverride: null,
            },
        },
    },
}) as VueConstructor<Vue & { promptRoot: { timestampOverride: string, locationOverride: MBAddressObj | null } }>;
