
import { openFilePicker } from '@/util.app';
import { defineComponent } from 'vue';
import AutoHeightTextArea from '../AutoHeightTextArea.vue';
import ImagePreviewList from '../ImagePreviewList.vue';
import SendIcon from '../icons/Send.vue';
import AddPhotoIcon from '../icons/AddPhoto.vue';

export default defineComponent({
    components: {
        AddPhotoIcon,
        AutoHeightTextArea,
        ImagePreviewList,
        SendIcon,
    },

    i18n: {
        messages: {
            en: {
                addMedia: 'Add media',
                submit: 'Submit',
                error: 'An error occurred',
            },

            es: {
                addMedia: 'Agregar medios',
                submit: 'Entregar',
                error: 'Ocurrió un error',
            },
        },
    },

    props: {
        postId: { type: String, required: true },
        uniqueId: { type: String, required: true },
    },

    data() {
        return {
            acceptedFileTypes: 'image/*, video/*',
            files: [] as File[],
            content: '',
            uploadProgress: -1,
            error: null as Error | null,
        };
    },

    computed: {
        totalMediaSize() {
            return this.files.map(file => file.size).reduce((a, b) => a + b, 0);
        },
    },

    methods: {
        async handleMediaButtonClick() {
            const files = await openFilePicker(this.acceptedFileTypes, true, true);
            this.files.push(...files);
        },

        async handleSubmit() {
            try {
                this.uploadProgress = 0;

                const mediaIds: string[] = [];

                for (const file of this.files) {
                    let previousChunk = 0;
                    const { data: media } = await this.$store.dispatch('uploadMedia', {
                        file,
                        onUploadProgress: (event: ProgressEvent) => {
                            const { loaded } = event;
                            const currentChunk = loaded - previousChunk;
                            this.uploadProgress += currentChunk;
                            previousChunk = loaded;
                        },
                    });

                    mediaIds.push(media.id);
                }

                const { error } = await this.$store.dispatch('addCommentToPost', {
                    postId: this.postId,
                    uniqueId: this.uniqueId,
                    comment: this.content,
                    mediaIds,
                });

                if (error) throw error;

                this.content = '';
                this.files = [];
            } catch (error) {
                // Errors are handled globally.
                console.error(error);
            } finally {
                this.uploadProgress = -1;
            }
        },
    },
});
