
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        expected: {
            type: Number,
            required: true,
        },

        actual: {
            type: Number,
            required: true,
        },

        related: {
            type: Array as () => number[],
            default: () => [],
        },
    },

    computed: {
        colder(): boolean {
            return this.actual < this.expected;
        },

        roundDifference(): number {
            return Math.round(this.actual) - Math.round(this.expected);
        },
    }
});
