
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import { Comment, CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import AutoHeightTextArea from '../AutoHeightTextArea.vue';
import SlippyCarousel from '../SlippyCarousel.vue';
import UniversalImage from '../UniversalImage.vue';

export default Vue.extend({
    components: {
        AutoHeightTextArea,
        MarkdownOutput,
        SlippyCarousel,
        UniversalImage,
    },

    props: {
        comment: {
            type: Object as () => Comment,
            required: true,
        },
    },

    data() {
        return {
            editing: false,
            draftText: '',
            showingDeleteDialog: false,
            saving: 0,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },

    methods: {
        async toggleEditing() {
            if (this.editing) {
                this.editing = false;
            } else {
                if (this.draftText === '') {
                    this.draftText = this.comment.textBody;
                }

                this.editing = true;

                await this.$nextTick();

                const editField = this.$refs.editField as Vue | null;
                if (editField?.$el instanceof HTMLElement) {
                    editField.$el.focus();
                }
            }
        },

        async handleSubmit() {
            if (this.draftText.trim() === '') {
                this.showingDeleteDialog = true;
            } else {
                this.saveChanges();
            }
        },

        actuallyDelete() {
            this.draftText = '';
            this.saveChanges();
        },

        async saveChanges() {
            try {
                this.saving += 1;

                const { error } = await this.$store.dispatch('updateComment', {
                    comment: this.comment,
                    updates: { textBody: this.draftText },
                });

                if (error) {
                    throw error;
                }

                this.editing = false;
                this.draftText = '';
            } catch (error) {
                console.error(error);
                this.$store.dispatch('alertUser', { type: 'error', message: 'Could not update comment' });
            } finally {
                this.saving -= 1;
                this.showingDeleteDialog = false;
            }
        },
    }
});
