
import Vue from '@/vueTyped';
import { trackPostView } from '@/tracking';

const VIEW_TIMEOUT = 1500;

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default: 'div',
        },

        postId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            observer: null as IntersectionObserver | null,
            onScreen: false,
            timeout: -1,
        };
    },

    watch: {
        onScreen(onScreen) {
            if (onScreen && this.timeout === -1) {
                this.timeout = setTimeout(this.recordView, VIEW_TIMEOUT) as unknown as number;
            } else if (!onScreen && this.timeout !== -1) {
                clearTimeout(this.timeout);
                this.timeout = -1;
            }
        },
    },

    mounted() {
        this.observer = new IntersectionObserver(this.handleIntersectionChange);
        this.observer.observe(this.$el);
    },

    beforeDestroy() {
        this.observer?.disconnect();
    },

    methods: {
        handleIntersectionChange([entry]: IntersectionObserverEntry[]) {
            this.onScreen = entry.isIntersecting;
        },

        async recordView() {
            this.$emit('view');
            if (this.postId) {
                try {
                    await this.$store.state.apiClient.post(`/posts/${this.postId}/views`);
                    trackPostView(this.postId);
                } catch (error) {
                    console.error(error);
                }
            }
        },
    },
});
