
import Vue from '@/vueTyped';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                message: '{when} {mark} {description}',
                thisDay: 'This day’s | this day’s',
                today: 'Today’s | today’s',
                marks: {
                    high: 'high',
                    low: 'low',
                    both: 'temps',
                },
                hotter: {
                    extreme: 'is extremely high | are extremely high',
                    high: 'is much higher | are much higher',
                    normal: 'is higher | are higher',
                    nonGaussian: 'could be abnormally high*',
                    nonGaussianWithoutAsterisk: 'could be abnormally high',
                },
                colder: {
                    extreme: 'is extremely low | are extremely low',
                    high: 'is much lower | are much lower',
                    normal: 'is lower | are lower',
                    nonGaussian: 'could be abnormally low*',
                    nonGaussianWithoutAsterisk: 'could be abnormally low',
                },
                and: 'and',
                comparedTo: 'compared to last year! | compared to the last {count} years!',
            },
        },
    },

    props: {
        analysis: { type: Object, required: true },
        overridden: { type: Boolean, default: false },
        today: { type: Boolean, default: false },
        noAsterisk: { type: Boolean, default: false },
    },

    computed: {
        summary(): { [mark: string]: { direction: string, deviation: string }} {
            const results = {
                high: {
                    direction: '',
                    deviation: '',
                },

                low: {
                    direction: '',
                    deviation: '',
                },
            };

            type ValidKey = keyof typeof results;

            for (const mark of Object.keys(results)) {
                const difference = this.analysis[mark] - this.analysis[`${mark}Expectation`];
                results[mark as ValidKey].direction = difference < 0 ? 'colder' : difference > 0 ? 'hotter' : '';

                const deviation = this.analysis[`${mark}Deviation`];

                if (deviation >= 1 && !this.analysis[`${mark}IsGaussian`]) {
                    results[mark as ValidKey].deviation = this.noAsterisk ? 'nonGaussianWithoutAsterisk' : 'nonGaussian';
                } else if (deviation >= 3) {
                    results[mark as ValidKey].deviation = 'extreme';
                } else if ( deviation >= 2) {
                    results[mark as ValidKey].deviation = 'high';
                } else if (deviation >= 1) {
                    results[mark as ValidKey].deviation = 'normal';
                } else {
                    delete results[mark as ValidKey];
                }
            }

            if (
                results.high && results.low &&
                results.high.direction === results.low.direction &&
                results.high.deviation === results.low.deviation
            ) {
                return { both: results.high };
            } else {
                return results;
            }
        },
    },
});
