
import Vue from '@/vueTyped';
import scroll from 'vuetify/lib/directives/scroll';

export default Vue.extend({
    directives: { scroll },

    props: {
        value: {
            type: Number,
            default: 0,
        },

        dotPosition: {
            type: String,
            default: 'hidden', // Or "top" or "bottom"
        },

        prevNextArrows: {
            type: Boolean,
            default: false,
        },

        floatingArrows: {
            type: Boolean,
            default: false,
        },

        fitEachPanel: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            panelCount: 0,
            internalValue: -1,
            averageVisiblePanelHeight: 'auto',
            recentExternalValueChanges: 0,
            recentScrollEvents: 0,
        };
    },

    watch: {
        value(value: number) {
            this.recentExternalValueChanges += 1;
            this.internalValue = value;

            setTimeout(() => {
                this.recentExternalValueChanges -= 1;
            }, 50);
        },

        async internalValue(internalValue: number) {
            if (this.recentExternalValueChanges === 0) {
                this.$emit('input', internalValue);
            }
        },
    },

    mounted() {
        this.countPanels();
        this.internalValue = this.value;
    },

    updated() {
        this.countPanels();
    },

    methods: {
        countPanels() {
            this.panelCount = (this.$refs.panels as HTMLDivElement).children.length;
        },

        scrollTo(panelIndex: number) {
            panelIndex = ((panelIndex % this.panelCount) + this.panelCount) % this.panelCount;
            const carousel = this.$refs.panels as HTMLDivElement;
            const panel = carousel.children[panelIndex] as HTMLElement;
            carousel.scrollLeft = panel.offsetLeft - carousel.offsetLeft - carousel.clientLeft;
        },

        handleLoad(event: Event) {
            const target = event.target as HTMLElement | null;
            if (this.fitEachPanel && target && target.offsetHeight !== 0) {
                this.fitVisiblePanels(this.internalValue);
            }
        },

        handleScroll() {
            const carousel = this.$refs.panels as HTMLDivElement;

            const scrollX = Math.abs(carousel.scrollLeft);
            const scrollAmount = scrollX / (carousel.scrollWidth - carousel.clientWidth);
            const visiblePanelIndices = scrollAmount * (carousel.children.length - 1);

            const centerPanelIndex = Math.round(visiblePanelIndices);
            this.internalValue = centerPanelIndex;

            this.recentScrollEvents += 1;

            setTimeout(() => {
                this.recentScrollEvents -= 1;

                if (this.recentScrollEvents === 0) {
                    this.handleApproximateScrollEnd();
                }
            }, 100);
        },

        handleApproximateScrollEnd() {
            if (this.fitEachPanel) {
                this.fitVisiblePanels(this.internalValue);
            }

            this.checkFocus();
        },

        // Note, this supports decimals but browsers behave inconsistently when changing height while scrolling.
        fitVisiblePanels(visiblePanelIndex: number) {
            const carousel = this.$refs.panels as HTMLDivElement;

            let startPanel = carousel.children[Math.floor(visiblePanelIndex)] as HTMLElement;
            let endPanel = carousel.children[Math.ceil(visiblePanelIndex)] as HTMLElement | undefined ?? startPanel;

            const endPanelWeight = visiblePanelIndex % 1;
            const startPanelWeight = 1 - endPanelWeight;

            startPanel.style.maxHeight = '1px';
            endPanel.style.maxHeight = '1px';

            const averageHeight = startPanel.scrollHeight * startPanelWeight + endPanel.scrollHeight * endPanelWeight;
            this.averageVisiblePanelHeight = `${averageHeight}px`;

            startPanel.style.maxHeight = '';
            endPanel.style.maxHeight = '';
        },

        checkFocus() {
            if (
                document.activeElement &&
                this.$el.contains(document.activeElement) &&
                document.activeElement.getBoundingClientRect().left as number < 0
            ) {
                const panel = (this.$refs.panels as HTMLDivElement).children[Math.round(this.internalValue)] as HTMLElement | undefined;
                if (panel) {
                    const tabIndexWas = panel.tabIndex;
                    panel.tabIndex = -1;
                    panel.focus();
                    panel.tabIndex = tabIndexWas;
                    console.log(panel, document.activeElement);
                }
            }
        }
    },
});
