import { render, staticRenderFns } from "./DeviationDescription.vue?vue&type=template&id=2e26a1ef"
import script from "./DeviationDescription.vue?vue&type=script&lang=ts"
export * from "./DeviationDescription.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports