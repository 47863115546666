export type WeatherAnalyticsResponse = {
    weather_analyses: {
        data_characteristics: {
            day_high_temperature: number; // 46.51
            day_high_temperature_datetime: string; // "2021-12-09T19:00:00-06:00"
            day_low_temperature: number; // 41.61
            day_low_temperature_datetime: string; // "2021-12-10T05:00:00-06:00"
            hour_temperature: number; // 45.1
            past_years: string; // "30"
            past_years_end: number; // 2020
            past_years_start: number; // 1991
            week_interval_day_end: number; // 14
            week_interval_day_start: number; // 8
            week_interval_month: string; // "Dec"
        },
        temperature_analyses: {
            week_interval: {
                daily_high_history_analyses: {
                    missing_data: {
                        drop_nan: boolean; // true
                        missing_percentage: number; // 0
                        nan_percentage: number; // 0
                        size_with_nan: number; // 210
                        size_without_nan: number; // 210
                    },
                    gaussian_distribution: {
                        alpha: number; // 0.05
                        errors: null;
                        is_gaussian: boolean; // true
                        p_value: number; // 0.42674747109413147
                        size: number; // 210
                        statistic: number; // 0.9930405616760254
                    },
                    descriptive_statistics: {
                        iqr: number; // 12.575000000000003
                        mean: number; // 38.14809523809524
                        median: number; // 37.5
                        q1: number; // 32.125
                        q3: number; // 44.7
                        size: number; // 210
                        standard_deviation: number; // 9.605999978605686
                    },
                    current_day_high_statistics: {
                        gaussian_distribution_outlier: boolean; // false
                        gaussian_distribution_probability_of_value_or_more_extreme: number; // 0.19201697093461517
                        iqr_outlier: boolean; // false
                        value: number; // 46.51
                        value_difference_from_mean: number; // 8.36190476190476
                    };
                };
                daily_low_history_analyses: {
                    missing_data: {
                        drop_nan: boolean; // true
                        missing_percentage: number; // 0
                        nan_percentage: number; // 0
                        size_with_nan: number; // 210
                        size_without_nan: number; // 210
                    },
                    gaussian_distribution: {
                        alpha: number; // 0.05
                        errors: null; // Not sure about this one.
                        is_gaussian: boolean; // false
                        p_value: number; // 0.016836583614349365
                        size: number; // 210
                        statistic: number; // 0.9838666319847107
                    },
                    descriptive_statistics: {
                        iqr: number; // 14.400000000000002
                        mean: number; // 26.24428571428571
                        median: number; // 26.9
                        q1: number; // 19.3
                        q3: number; // 33.7
                        size: number; // 210
                        standard_deviation: number; // 10.535085637604517
                    },
                    current_day_low_statistics: {
                        gaussian_distribution_outlier: boolean; // false
                        gaussian_distribution_probability_of_value_or_more_extreme: number; // 0.07234757206434048
                        iqr_outlier: boolean; // false
                        value: number; // 41.61
                        value_difference_from_mean: number; // 15.36571428571429
                    }
                },
            };
        };
    };
};

export type TidyWeatherAnalysis = {
    current: number;
    pastYears: number;

    highExpectation: number;
    high: number;
    highTime: Date;
    highDeviation: number;
    highIsGaussian: boolean;

    lowExpectation: number;
    low: number;
    lowTime: Date;
    lowDeviation: number;
    lowIsGaussian: boolean;
};

export default function tidyWeatherAnalysis(analysis: WeatherAnalyticsResponse): TidyWeatherAnalysis {
    return {
        current: analysis.weather_analyses.data_characteristics.hour_temperature,
        pastYears: parseFloat(analysis.weather_analyses.data_characteristics.past_years),
        highExpectation: analysis.weather_analyses.temperature_analyses.week_interval.daily_high_history_analyses.descriptive_statistics.mean,
        high: analysis.weather_analyses.data_characteristics.day_high_temperature,
        highTime: new Date(analysis.weather_analyses.data_characteristics.day_high_temperature_datetime),
        highDeviation: Math.abs((analysis.weather_analyses.temperature_analyses.week_interval.daily_high_history_analyses.current_day_high_statistics.value_difference_from_mean) / analysis.weather_analyses.temperature_analyses.week_interval.daily_high_history_analyses.descriptive_statistics.standard_deviation),
        highIsGaussian: analysis.weather_analyses.temperature_analyses.week_interval.daily_high_history_analyses.gaussian_distribution.is_gaussian,
        lowExpectation: analysis.weather_analyses.temperature_analyses.week_interval.daily_low_history_analyses.descriptive_statistics.mean,
        low: analysis.weather_analyses.data_characteristics.day_low_temperature,
        lowTime: new Date(analysis.weather_analyses.data_characteristics.day_low_temperature_datetime),
        lowDeviation: Math.abs((analysis.weather_analyses.temperature_analyses.week_interval.daily_low_history_analyses.current_day_low_statistics.value_difference_from_mean) / analysis.weather_analyses.temperature_analyses.week_interval.daily_low_history_analyses.descriptive_statistics.standard_deviation),
        lowIsGaussian: analysis.weather_analyses.temperature_analyses.week_interval.daily_low_history_analyses.gaussian_distribution.is_gaussian,
    };
}
